<template>
  <div class="test-result vx-row -mx-2 flex-nowrap">
    <vs-alert :active="testRunning" icon-pack="feather" icon="icon-loader" class="con-icon--spin">
      <span>Please wait while the script is running...</span>
    </vs-alert>
    <vx-card class="overflow-hidden w-full" v-if="testCompileErrors && testCompileErrors.length > 0">
      <ul>
        Errors
        <li v-for="(error, i) in testCompileErrors" :key="i"
          ><!-- {{ error.type }} -->
          <span v-if="error.type === 'compile'"
            >Problems Compiling<br />
            {{ error.message }} - line {{ error.line }}, column {{ error.column }}
          </span>

          <span v-if="error.type === 'auth'"
            >Authentication Error<br />
            {{ error.message }}
          </span>
        </li>
      </ul>
    </vx-card>
    <Screencasts v-if="hasTestResults && item" :item="item" />
  </div>
</template>

<script>
import Screencasts from '@/views/captures/components/Screencasts';
import { mapState } from 'vuex';

export default {
  components: {
    Screencasts
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('captures', ['item', 'testRunning', 'testCompileErrors', 'hasTestResults'])
  },
  methods: {},
  mounted() {},
  watch: {}
};
</script>

<style lang="scss">
.test-result .vx-card__body {
  padding: 0 !important;
}
</style>

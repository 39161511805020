require('moment');
import moment from 'moment-timezone';
import prettyCron from '@/plugins/resources/prettycron';

import store from '@/store/store';

export default class VxCronBuilder {
  intervalOptions = [
    {
      key: 'none',
      text: 'N/a'
    },
    {
      key: 'daily',
      text: 'Daily'
    },
    {
      key: 'weekly',
      text: 'Weekly',
      singleSelection: true
    },
    {
      key: 'monthly',
      text: 'Monthly',
      singleSelection: true
    }
  ]
  daysOptions = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  get hoursOptions () {
    const out = new Array();
    for (let i = 0; i <= 23; i++) {
      out.push({
        value: i,
        label: `${i < 10 ? `0${i}` : i}`
      })

    }
    return out;
  }
  get minutesOptions () {
    const out = new Array();
    for (let i = 0; i <= 55; i++) {
      if (i % 5 === 0) {
        out.push({
          value: i,
          label: `${i < 10 ? `0${i}` : i}`
        })
      }
    }
    return out;
  }
  timezoneOptions = moment.tz.names();
  occuranceOptions = [
    {
      flag: '#1',
      text: '1st'
    },
    {
      flag: '#2',
      text: '2nd'
    },
    {
      flag: '#3',
      text: '3rd'
    },
    {
      flag: '#4',
      text: 'last'
    }
  ]

  config = {

  }

  constructor() {
    this.$mode = 'daily';
    // this.intervalOptions = VxCronBuilder.intervalOptions;

    this.selectedTimezone = store.getters['accounts/userTimezone'] || moment.tz.guess();

    this.current = moment();
    this.currentDay = this.current.format('ddd').toUpperCase();
    this.params = {
      seconds: 0,
      minutes: 0,
      hours: 0,
      dayOfMonth: '?',
      month: '*',
      dayOfWeek: this.currentDay,
      year: '*'
    };

    this.selectedDays = [...this.daysOptions];
    this.selectedOccurance = this.occuranceOptions[0].flag;
    this.selectedHour = this.params.hours;
    const currentMinute = this.params.minutes;
    const remainder = currentMinute % 5;
    this.selectedMinutes = parseInt(remainder === 0 ? currentMinute : currentMinute - remainder);

  }

  updateDayOfWeek (day) {
    const selectedDays = this.selectedDays;
    const index = selectedDays.indexOf(day);
    if (index > -1) {
      if (selectedDays.length > 1) selectedDays.splice(index, 1);
      this.params.dayOfWeek = this.getDayOfWeek()
      return;
    }

    if (!this.currentMode.singleSelection) {
      selectedDays.push(day);
    } else {
      this.selectedDays = [day];
    }

    this.params.dayOfWeek = this.getDayOfWeek()
  }

  getDayOfWeek () {
    return this.$mode !== 'monthly'
      ? this.selectedDays.length === this.daysOptions.length
        ? '*'
        : this.selectedDays.join(',')
      : this.selectedDays[0] + this.occurance
  }

  build () {
    this.params.minutes = this.minutes;
    this.params.hours = this.hours;
    this.params.dayOfWeek = this.getDayOfWeek()
    const { seconds, minutes, hours, dayOfMonth, month, dayOfWeek } = this.params;

    const tz = moment().tz(this.timezone);
    const utcHours = tz.hour(hours).utc().hour();
    return `${seconds} ${minutes} ${utcHours} ${dayOfMonth} ${month} ${dayOfWeek}`;
  }

  get currentMode () {
    return this.intervalOptions.find(item => item.key === this.$mode);
  }

  get selectedDay () {
    const dayIndex = this.daysOptions.indexOf(this.selectedDays[0]);
    return moment()
      .isoWeekday(dayIndex + 1)
      .format('dddd');
  }

  get mode () {
    return this.$mode;
  }
  set mode (value) {
    this.$mode = value;
    if (this.currentMode.singleSelection) {
      if (this.selectedDays.includes(this.currentDay)) {
        this.selectedDays = [this.currentDay];
      } else {
        this.selectedDays = [this.selectedDays[0]];
      }
    }
    this.params.dayOfWeek = this.getDayOfWeek()
  }

  get occurance () {
    return this.selectedOccurance;
  }
  set occurance (value) {
    this.selectedOccurance = value;
    this.params.dayOfWeek = this.getDayOfWeek()
  }

  get hours () {
    return this.selectedHour;
  }
  set hours (value) {
    this.selectedHour = Number(value);
    this.params.hours = Number(value);
  }

  get minutes () {
    return this.selectedMinutes;
  }
  set minutes (value) {
    this.selectedMinutes = Number(value);
    this.params.minutes = Number(value);
  }

  get timezone () {
    return this.selectedTimezone;
  }
  set timezone (value) {
    this.selectedTimezone = value;
  }

  set schedule (cronExp) {
    const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek] = cronExp.split(/\s/);
    this.params.seconds = seconds;
    this.minutes = minutes;
    this.hours = moment.utc(hours, 'H').tz(this.timezone).hour();
    this.params.dayOfMonth = dayOfMonth;
    this.params.month = month;
    this.params.dayOfWeek = dayOfWeek;

    if (dayOfWeek === '*') return;
    if (dayOfWeek.includes('#')) {
      this.mode = 'monthly';
    } else if (dayOfWeek.split(/,/).length > 1) {
      this.mode = 'daily';
      this.selectedDays = dayOfWeek.split(/,/);
    } else if (dayOfWeek.split(/,/).length === 1) {
      this.selectedDays = [dayOfWeek];
      this.mode = 'weekly'
    } else {
      this.mode = 'none'
    }
  }

  get cronHumanDescription () {
    /* eslint no-unused-vars: 0 */
    const { minutes, hours, dayOfMonth, month, dayOfWeek } = this.params;
    return (userTimezone) => {
      if (userTimezone === this.timezone) return prettyCron(`${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek}`);
      const tzHours = moment.tz(this.timezone).hour(hours).tz(userTimezone).hour();
      return prettyCron(`${minutes} ${tzHours} ${dayOfMonth} ${month} ${dayOfWeek}`);
    }
  }
}

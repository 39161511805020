<template>  
    <div>
      <vue-file-toolbar-menu :content="my_menu" />
      <div>
        <div id="editorcontainer" style="min-height: 500px;" v-observe-visibility="visibilityChanged"></div>
      </div>
    </div>
</template>

<script>
  //import MonacoEditor from 'vue-monaco'
  //import MonacoEditor from 'monaco-editor-vue';
  import * as monaco from  'monaco-editor';
  import { InMemoryStorageService } from 'monaco-editor/esm/vs/platform/storage/common/storage';
  import { InstantiationService } from 'monaco-editor/esm/vs/platform/instantiation/common/instantiationService';
  import { SuggestController } from 'monaco-editor/esm/vs/editor/contrib/suggest/suggestController';

  import VueFileToolbarMenu from 'vue-file-toolbar-menu'
  import { AutoTypings, LocalStorageCache } from 'monaco-editor-auto-typings';
  import monacoTypes from '@/assets/types/monaco_types.json'
  //import 'monaco-editor/esm/vs/language/json/monaco.contribution';
/*  import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
  import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution';
  import 'monaco-editor/esm/vs/editor/editor.all';
  ss
*/
/* eslint-disable */
  
//monaco.vs.platform.storage.common.storage.
class MyInMemoryStorageService extends InMemoryStorageService {

        getBoolean(key) {
            if (key === "expandSuggestionDocs") // open the suggestion box automatically
                return true;

            return false;
        }
}

class MyInstantiationService extends InstantiationService {
  //createInstance(ctorOrDescriptor: any | SyncDescriptor<any>, ...rest: any[]): any {
  createInstance(ctorOrDescriptor, ...rest) {
    console.log('enter createInstance...');
    return super.createInstance(ctorOrDescriptor,...rest);
  }
}

export default {
  components: {
    //MonacoEditor: MonacoEditor
    VueFileToolbarMenu
  },
  props: ['value'],
  data() {
    return {
          isVisible: true,
  //    updatedScript: '',
    //  model: this.$attrs.script || 'browser.goto()',
      options: {
        automaticLayout: true,
        language: "javascript",

        lineNumbers: "on",
        roundedSelection: false,
        scrollBeyondLastLine: false,
        readOnly: false,
        theme: "vs-dark",
      }
    }
  },
  mounted() {
// validation settings




    
  },

  computed: {
    my_menu () {
      return [

        // Undo / redo commands
        { title: "Undo", icon: "undo", disabled: !this.can_undo, hotkey: this.isMacLike ? "command+z" : "ctrl+z", click: () => this.undo() },
        { title: "Redo", icon: "redo", disabled: !this.can_redo, hotkey: this.isMacLike ? "shift+command+z" : "ctrl+y", click: () => this.redo() },

        { text: "My Menu", menu: [
          { text: "Item 1", click: () => alert("Action 1") },
          { text: "Item 2", click: () => alert("Action 2") }
        ] }, {
          text: "My Button",
          active: this.happy,
          icon: this.happy ? "sentiment_very_satisfied" : "sentiment_satisfied",
          click: () => { this.happy = !this.happy }
        }
      ]
    }
  },

  methods: {


    _emitChange(value, event) {
      this.$emit('change', value, event);
      this.$emit('input', value);
      //this.model = value;
      //this.$attrs.script=value;
    },

    visibilityChanged (isVisible, entry) {
      //console.log('codeEditor' , this.codeEditor!=null, isVisible);

      this.isVisible = isVisible

      if (!this.codeEditor && isVisible) {        
        if (this.isCreatingCodeEditor) return;
        this.isCreatingCodeEditor=true;



//Register a custom snippet
monaco.languages.registerCompletionItemProvider('javascript', {
      provideCompletionItems: () => { // model, position
        return {
          suggestions: [
          {
            label: 'for: Array',
            kind: monaco.languages.CompletionItemKind.Snippet,
            documentation: 'Iterate over an Array',
            insertText: [
              'for(let i=0; i < arr.length; i++){',
              '\tlet elem = arr[i];',
              '',
              '}'].join('\n'),
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
          }
        ]
        }
      }
    });


        // extra libraries
        var libSource =
          `
          //import { Browser, Page } from 'puppeteer-core';
          import {Browser, Page} from 'puppeteer';
//          var browser : Browser;
  //        var page : Page;


          declare class Facts {
              /**
               * Returns the next fact now HEHE 11
               * @param text  Comment for parameter ´text´.
               * @returns      Comment for special return value.
               */
              static next(text number):string;
              //static browser : Browser;
          }
          
          //declare var browser : Browser;
          //declare var page : Page;
          `;        
        var libUri = 'ts:filename/facts.d.ts';

        monaco.languages.typescript.javascriptDefaults.addExtraLib(libSource, libUri);

        // When resolving definitions and references, the editor will try to use created models.
        // Creating a model for the library allows "peek definition/references" commands to work with the library.
        var curModel = monaco.editor.getModel(monaco.Uri.parse(libUri));
        if (!curModel) {
          //console.log('curModel', curModel);
          monaco.editor.createModel(libSource, 'typescript', monaco.Uri.parse(libUri));
        }

//console.log('monaco.languages.typescript.typescriptDefaults', monaco.languages.typescript.typescriptDefaults);
/*
monaco.languages.typescript.typescriptDefaults.setExtraLibs(
  [
    {
      content: 'export declare function add(a: number, b: number): number',
      filePath: 'file:///node_modules/@types/math/index.dx.ts'
    }
    ]
);
*/

/*
const model = monaco.editor.createModel(
    `import {add} from 'math';\nconst x = add(3, 5);\n`,
    'typescript',
    monaco.Uri.parse('file:///main.tsx')
);*/

//monaco.editor.create(document.getElementById('container'), {model});

//---In front js---
// validation settings
monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: true,
    noSyntaxValidation: false
});

// compiler options
monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    target: monaco.languages.typescript.ScriptTarget.ES6,
    allowNonTsExtensions: true,
    esModuleInterop: true,
    allowJs: true,//!!!Require for js
});

// compiler options
monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
    target: monaco.languages.typescript.ScriptTarget.ES2016,
    allowNonTsExtensions: true,
    esModuleInterop: true,
    moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
    module: monaco.languages.typescript.ModuleKind.System,
    noEmit: true,
    //typeRoots: ["@types/puppeteer"]
    //typeRoots: ["node_modules/@types"]
});

/*

const dtsExtractor = require('dts-extractor');

(async () => {
    const dts = await dtsExtractor.getDts({
        nodeModulesPath: './node_modules', // path in file system where packages located
        packages: ['xlstream', '@types/moment'], // list of packages 
    });

    console.log('dts', dts);
    for (const key of Object.keys(dts)) {
        monaco.languages.typescript.typescriptDefaults.addExtraLib(dts[key], key);
    }

})();
*/



    console.log('monacoTypes',monacoTypes);

    var dts = monacoTypes;
    console.log('dts', dts);
    for (const key of Object.keys(dts)) {
        //console.log('dts', key);
        var fileName = key.replace('@types/','');
        //console.log('fileName', fileName);
        if (fileName.indexOf(".d.ts")==-1) {
          continue;
        }

        var parts = fileName.split('/');
        var moduleName = parts[parts.length-2];

        console.log('moduleName name is ', moduleName);
        var codeDefinition = `declare module '` + moduleName + `' { ` + // declaring it as a module gets the global working
          dts[key] +
          `\n}`;
          console.log(codeDefinition);

        monaco.languages.typescript.typescriptDefaults.addExtraLib(
          codeDefinition

          , monaco.Uri.file(fileName));
    }

//monaco.languages.typescript.typescriptDefaults.addExtraLib(`import {Browser, Page} from 'puppeteer'; var browser : Browser; export browser;`,
  // monaco.Uri.file("globla-vars.d.ts"));

  monaco.languages.typescript.typescriptDefaults.addExtraLib(
    `import {Browser, Page} from 'puppeteer';
    declare global {
      const browser : Browser;
      const page : Page;
    }`)


// extra libraries
/*monaco.languages.typescript.typescriptDefaults.addExtraLib(
    `export declare function nextTo() : string`,
    'node_modules/@types/puppeteer/index.d.ts');
*/
monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSyntaxValidation: false
})


      var codeEditor = monaco.editor.create(document.getElementById('editorcontainer'), {
          value: this.value,
          //model: monaco.editor.createModel(`browser`,"typescript", monaco.Uri.file("main.tsx") ),
          language: 'typescript',
          theme: 'vs-dark'
        }, {
          storageService: new MyInMemoryStorageService(),
          instantiationService: new MyInstantiationService()

        });

      this.codeEditor = codeEditor;

/*
export class SuggestController implements IEditorContribution {

  public static readonly ID: string = 'editor.contrib.suggestController';

  public static get(editor: ICodeEditor): SuggestController {
    return 
*/

var contribObj = codeEditor.getContribution<SuggestController>(SuggestController.ID);
SuggestController.get(codeEditor).triggerSuggest();

  window.$("#editorcontainer").on( "click", "[monaco-visible-content-widget=true]", function() {
    if (window.completionItem) {
      //alert('you clicked on it and it was ' +window.completionItem.completion.detail + " ---- " +  window.completionItem.completion.documentation.value); 
      console.log(window.completionItem.completion.detail + " ---- " +  window.completionItem.completion.documentation.value);
      
      //var textUntilPosition = codeEditor.getModel().getValueInRange(window.completionItem.completion.range);
      //console.log('textUntilPosition', textUntilPosition);

      // https://pptr.dev/#?product=Puppeteer&version=v5.5.0&show=api-framewaitfornavigationoptions?
      // https://pptr.dev/#?product=Puppeteer&version=v5.5.0&show=api-framewaitfornavigationoptions
      // https://pptr.dev/#?product=Puppeteer&version=v5.5.0&show=framebasewaitfornavigationoptions
      // https://pptr.dev/#?product=Puppeteer&version=v5.5.0&show=api-pagewaitfornavigationoptions
      var methodStr = window.completionItem.completion.detail;
      const regex = /\(method\) (.+)\((.+)\)/gi;
      // (method) FrameBase.waitForNavigation(options?: NavigationOptions): Promise<Response> 
      var regexMatches = regex.exec(methodStr);
      var methodName = regexMatches[1];
      var parameterStr = regexMatches[2];
      // FrameBase.waitForNavigation(options?: NavigationOptions)
      
      methodName = methodName.toLowerCase().replace(".","");

      var parameters = parameterStr.split(",").map(paramValuePair => {
        return paramValuePair.split(":")[0].toLowerCase().replace("?","");
      });
      
      window.open ( 'https://pptr.dev/#?product=Puppeteer&version=v5.5.0&show=' + 'api-' + (methodName.replace("framebase","frame")) + parameters, '_blank' );
      

    }
  });


SuggestController.get(codeEditor).model.onDidSuggest(e => {
  console.log('Suggesting something...', e.completionModel.items);
  window.completionItem = null;
  if (e.completionModel.items.length === 1) {
    window.completionItem =e.completionModel.items[0];
  }
  /*
        if (e.completionModel.items.length === 0) {
          commitCharacterController.reset();
        }*/
      });
///contribObj.triggerSuggest();

console.log('contribObj', contribObj);

   codeEditor.onDidChangeModelContent(event => {
          const value = codeEditor.getValue();
          //console.log('newValue', value);
          

          this._emitChange(value, event);

        });

// so can type 
// Initialize auto typing on monaco editor. Imports will now automatically be typed!

const cache = new LocalStorageCache();

/*
console.log('monaco.languages.typescript.javascriptDefault', monaco.languages.typescript.javascriptDefault);
var MYDTS = `
monaco.languages.typescript.javascriptDefaults.addExtraLib(
    MYDTS, 'ts:filename/MYDTS.d.ts');
*/
/*
    monaco.languages.typescript.javascriptDefaults.addExtraLib(`
      import puppeteer from 'puppeteer-core';
      //export = puppeteer;
      //export as namespace puppeteer;
    `, 'global.d.ts');
*/

var autoTypingEnabled= false;

if (autoTypingEnabled) {
  var autoTypings = AutoTypings.create(codeEditor, {
    // Cache declaration files to local storage
    sourceCache: cache,

    preloadPackages:true,
     /*
     * Setting the option ``onlySpecifiedPackages`` to true makes this
     * property act as a whitelist for packages.
     *
     * Setting the option ``preloadPackages`` makes the packages specified
     * in this property load directly after initializing the auto-loader.
     */
  //  versions: [ { packageName: 'puppeteer-core' }],

    // Log progress updates to a div console
    onUpdate: (u, t) => {
      console.log('On Typing update', t);
    },

    // Log errors to a div console
    onError: e => {
      console.log('On Typing error', e);
    },

    // Print loaded versions to DOM
    onUpdateVersions: versions => {
      var loadedInfo = Object.entries(versions)
        .map(v => `<div>${v[0]}: yy ${JSON.stringify(v[1])}</div>`)
        .join('');

        console.log('On Typing loaded info', loadedInfo);


    },
  });

  //autoTypings.setVersions ( [ { packageName: 'puppeteer-core' /* '@types/puppeteer' */ }] ) ;
}

codeEditor.addAction({
      id: 'insert-text-at-cusor-command',
      label: 'Command Snippet x',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.F10],
      contextMenuGroupId: 'snippets',
      contextMenuOrder: 1.5,
      run: function (ed) {
        codeEditor.focus()
        codeEditor.trigger('keyboard', 'type', {text: "for"});
      }
});

        

      }

      console.log(entry)
    },

    onChange(newValue) {
      //console.log(newValue, this);
      //this.data.updatedScript = newValue;
    },
    editorDidMount(editor) {
      window.editor = editor;
      // Listen to `scroll` event
      editor.onDidScrollChange(e => {
        console.log(e)
      })
    }

  }
}

window.onresize = () => {
  console.log('Window resize');
  //window.editor.layout({ height: 500 });
};



</script>
<template>
  <vx-card>
    <div class="vx-row -mx-2">
      <div class="vx-col px-2 w-full mb-3">
        <!-- URL -->
        <vx-field
          v-if="data.id"
          type="input"
          class="w-full"
          label="URL"
          :value="urlPath"
          :disabled="true"
          description-text="Full address of the web page you'd like to capture."
        />
        <vx-field
          v-else
          type="input"
          class="w-full"
          label="URL"
          v-model="form.url"
          v-validate="'required'"
          description-text="Full address of the web page you'd like to capture."
        />
      </div>
      <div class="vx-col px-2 md:w-2/3 w-full mb-3">
        <!-- Name -->
        <vx-field
          type="input"
          class="w-full"
          label="Title"
          v-model="form.name"
          description-text="The title of the website will be used for this capture if you don't enter one"
        />
      </div>
      <div class="vx-col px-2 md:w-1/3 w-full mb-3">
        <!-- Interval -->
        <vx-field
          type="select"
          autocomplete
          class="w-full"
          label="Interval"
          v-model="builder.mode"
          description-text="How often captures will be taken."
          :options="{
            value: 'key',
            text: 'text',
            items: builder.intervalOptions
          }"
          :disabled="config.interval === 'disabled'"
        />
      </div>
      <!-- Advance Options -->
      <div class="vx-col px-2 w-full mb-3" v-if="!noMonitoringInterval">
        <vs-checkbox class="my-5" v-model="isAdvanceOptionEnabled">Advance options</vs-checkbox>
        <transition name="zoom-fade">
          <div v-show="isAdvanceOptionEnabled">
            <div v-if="isAdvanceOptionEnabled" class="vx-row mb-base">
              <div class="vx-col w-1/3">
                <vx-field
                  type="switch"
                  size="large"
                  class="mt-5 w-full"
                  label="Capture HTML"
                  v-model="form.captureHtml"
                  :config="{
                    on: {
                      icon: 'icon-check-circle',
                      text: 'Yes'
                    },
                    off: {
                      icon: 'icon-slash',
                      text: 'No'
                    }
                  }"
                />
              </div>
              <div class="vx-col w-1/3">
                <vx-field
                  type="switch"
                  size="large"
                  class="mt-5 w-full"
                  label="Capture HAR"
                  v-model="form.captureHar"
                  :config="{
                    on: {
                      icon: 'icon-check-circle',
                      text: 'Yes'
                    },
                    off: {
                      icon: 'icon-slash',
                      text: 'No'
                    }
                  }"
                />
              </div>
              <div class="vx-col w-1/3">
                <vx-field
                  type="switch"
                  size="large"
                  class="mt-5 w-full"
                  label="Capture Web Archive"
                  v-model="form.captureWarc"
                  :config="{
                    on: {
                      icon: 'icon-check-circle',
                      text: 'Yes'
                    },
                    off: {
                      icon: 'icon-slash',
                      text: 'No'
                    }
                  }"
                />
              </div>
            </div>
            <VxCronBuilder :builder="builder" v-model="scheduleAndTimeZone" />
          </div>
        </transition>
        <vs-divider class="my-5"></vs-divider>
        <p class="text-sm font-semibold mb-2">Scheduling summary</p>
        <p class="text-sm">
          We'll create a first capture just after the creation of the monitor. Then
          <span class="font-semibold">
            {{ form.schedule | humanDescription(form.timeZone) }}
            <small>({{ timezoneAbbv }})</small> </span
          >.
        </p>
        <vs-divider class="mt-3 mb-5"></vs-divider>
      </div>
      <!-- Templates -->
      <div class="vx-col px-2 w-full mb-3">
        <VxSelectTemplate name="template" v-model="form.template" v-validate="'template'" :disabled="form.id" />
      </div>
      <!-- Advance Section -->
      <div class="vx-col px-2 w-full mb-3">
        <div class="my-5 flex">
          <vs-checkbox v-model="isTestScriptEnabled" :disabled="noMonitoringInterval">Add a test script</vs-checkbox>
          <vs-button
            type="border"
            v-if="isTestScriptEnabled"
            @click="openScriptRecorder"
            size="small"
            color="#333"
            class="px-3 ml-auto"
            :disabled="form.id ? false : !form.url"
            ><span class="text-sm">Record Script</span></vs-button
          >
        </div>
        <transition name="zoom-fade">
          <FormTestScript v-show="isTestScriptEnabled" ref="scriptComponent" :script="form.script" @submit="beforeRunTestScript" />
        </transition>
        <p class="text-xs mt-2" v-if="noMonitoringInterval">
          <feather-icon icon="InfoIcon" svgClasses="h-4 w-4 mr-1 transform translate-y-1" />
          Monitoring without schedule will run tests so a test script is required to make this action.
        </p>
      </div>
      <!-- Buttons -->
      <div class="vx-col px-2 w-full mt-base mb-3 flex justify-end">
        <vs-button color="success" @click="submit" :disabled="noMonitoringInterval && !testScript">
          <span v-if="noMonitoringInterval">Save test</span>
          <span v-else>{{ form.id ? 'Save' : 'Start' }} monitoring</span>
        </vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapGetters } from 'vuex';

import CronBuilder from '@/components/vx-cron-builder';
import VxCronBuilder from '@/components/vx-cron-builder/VxCronBuilder.vue';
import VxSelectTemplate from '@/components/VxSelectTemplate';
import Monitoring from '@/entity/Monitoring';
import FormTestScript from '@/views/monitoring/components/FormTestScript';
import ScriptRecorder from '@/views/monitoring/components/ScriptRecorder';

export default {
  props: {
    data: {
      type: Object,
      required: false
    },
    config: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isAdvanceOptionEnabled: Boolean((this.data || {}).id),
      isTestScriptEnabled: Boolean((this.data || {}).script),
      builder: new CronBuilder(),
      form: new Monitoring(this.data)
    };
  },
  computed: {
    scheduleAndTimeZone: {
      get() {
        if (!this.form.schedule && !this.form.timeZone) return null;
        return {
          schedule: this.form.schedule,
          timeZone: this.form.timeZone
        };
      },
      set(cron) {
        this.form.schedule = cron.schedule;
        this.form.timeZone = cron.timeZone;
      }
    },
    urlPath() {
      if (!this.isMounted || !(this.data || {}).url) return undefined;
      try {
        return this.$options.filters.urlPath(this.data.url);
      } catch (error) {
        return undefined;
      }
    },
    ...mapGetters('accounts', ['userTimezone']),
    noMonitoringInterval() {
      return this.builder.mode === 'none';
    },
    testScript() {
      const { scriptComponent } = this.$refs;
      return scriptComponent && scriptComponent.$refs.testScript ? scriptComponent.$refs.testScript.model : undefined;
    },
    timezoneAbbv() {
      return this.$moment.tz(this.form.timeZone || this.userTimezone).format('z');
    }
  },
  methods: {
    async submit() {
      var me = this;
      return await this.$validator.validateAll().then(async valid => {
        if (valid) {
          const { form, testScript, noMonitoringInterval, isTestScriptEnabled, config } = this;
          if (noMonitoringInterval && !testScript) return;
          const item = await this.$store.dispatch('monitors/update', {
            ...form,
            script: isTestScriptEnabled ? testScript || form.script : '',
            captureScreencast: isTestScriptEnabled ? true : false,
            ...(noMonitoringInterval
              ? {
                  schedule: null,
                  timeZone: null
                }
              : {})
          });
          me.$emit('submit', item);
          
          //me.$refs.component.$emit('cancel');

          if (config.preventRedirect) return;
          if (item.id) me.$router.push(`/monitoring/${item.id}`);
        }
      });
    },
    beforeRunTestScript(submit) {
      this.$validator.validateAll().then(valid => {
        const notify = document.querySelector('.vs-notifications.activeNoti');
        if (notify) notify.click();
        if (valid) return submit(this.form);

        this.$vs.notify({
          title: 'Error',
          text:
            (document.querySelector('#monitoring-area .vx-field span.text-danger:not([style="display: none;"])') || {}).innerText ||
            'Please check, there are items that require your attention.',
          color: 'danger',
          time: 10000
        });
      });
    },
    openScriptRecorder() {
      document.domain = "pagewitness.com";
      const url = new URL(this.form.url);
      url.host = url.host.replaceAll(".","-") + '-pwproxy.pagewitness.com';
      var fullUrl = url.toString()
      const { scriptComponent } = this.$refs;

      this.$vxPopup.open({
        attrs: {
          title: `Record script for ${this.form.url}`,
          fullscreen: true,
          class: 'screen-recorder'
        },
        config: {
          component: ScriptRecorder,
          data: { url: `${fullUrl}` }
        }
      }).then(async component => {
            console.log(component);
            return await new Promise(resolve => {
              component.$on('submit', result => {
                scriptComponent.$refs.testScript.model =((scriptComponent.$refs.testScript.model || '') + "\r\n" + result).trim();

                component.$emit('close');
                resolve(result);
              });
              component.$on('cancel', () => resolve(null));
            });
          });

    }
  },
  mounted() {
    this.$nextTick(() => {
      const { config } = this;
      if (config && config.builder) {
        this.builder.mode = config.builder.mode;
      }
      // this.openScriptRecorder();
    });
  },
  watch: {
    data(data) {
      this.form = new Monitoring(data);
      if (this.form.id) this.isAdvanceOptionEnabled = true;
      if (this.form.script) this.isTestScriptEnabled = true;
    },
    'builder.mode'(mode) {
      if (mode === 'none') this.isTestScriptEnabled = true;
    }
  },
  components: {
    VxCronBuilder,
    VxSelectTemplate,
    'FormTestScript': FormTestScript
  }
};
</script>

<template>
  <VxTabs class="monitoring-test-script" :config="config" ref="vxTabs">
    <div slot="actions" class="flex">
      <vs-button @click="emitSubmit" size="small" icon-pack="feather" icon="icon-play" icon-after class="px-3" :disabled="!hasScript || testRunning"
        >Run script</vs-button
      >

      <vs-button @click="stopTesting" size="small" icon-pack="feather" icon="icon-stop-circle" icon-after class="px-3 ml-2" :disabled="!testRunning"
        >Cancel
      </vs-button>
    </div>
    <VxTab icon="icon-code" title="Test script">
      <TestScript v-model="testScript" />
    </VxTab>
    <VxTab icon="icon-terminal" title="Test results" :disabled="!hasScreenCasts">
      <TestResults />
    </VxTab>
    <VxTab
      icon="icon-image"
      title="Screenshots"
      :badgeText="hasScreenshots ? item.screenshots.length : null"
      :disabled="!hasScreenshots && !testRunning"
    >
      <Screenshots />
    </VxTab>
  </VxTabs>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TestScript from '@/views/monitoring/components/TestScript';
import TestResults from '@/views/monitoring/components/TestResults';
import Screenshots from '@/views/monitoring/components/Screenshots';
import auth0 from '@/auth0';
//

//console.log("connecting to host: ", host_ip);

function runTest(data) {
  console.log('runTest....', data);

  var testData = {
    source: 'aws.events',
    time: '1970-01-01T00:00:00Z',
    detail: {
      id: 'myidmyid',
      url: data.url,
      save: {
        favicon: false,
        html: false,
        warc: false,
        har: false,
        screencast: true
      },
      script: data.script,
      puppeteer: {
        viewports: data.template.viewPorts // JSON.parse(JSON.stringify(data.viewports))
      }
    }
  };

  console.log('running test with', testData);
  //this.$socket.emit('emit_method', data)

  this.$socket.emit('execute', {
    tenantId: data.tenantId,
    authentication: auth0.getToken(),
    payload: JSON.stringify(testData)
  });
}

export default {
  components: {
    TestScript,
    TestResults,
    Screenshots
  },
  data() {
    return {
      testScript: null,
      test: true,
      config: {
        settings: {
          size: 'small'
        }
      }
    };
  },
  computed: {
    ...mapState('captures', ['item', 'testRunning', 'hasTestResults', 'testCompileErrors']),
    hasScript() {
      if (!this.isMounted) return false;
      try {
        return this.testScript != null && this.testScript.trim().length > 0;
      } catch (error) {
        return false;
      }
    },
    hasScreenCasts() {
      var output = false;
      if (!this.isMounted) output = false;
      else output = this.item && this.item.screencasts && this.item.screencasts.length > 0 ? true : false;

      return output;
    },
    hasScreenshots() {
      var output = false;

      if (!this.isMounted) output = false;
      else output = this.item && this.item.screenshots && this.item.screenshots.length > 0 ? true : false;

      return output;
    }
  },
  sockets: {
    connect: function() {
      console.log('socket connected');
    } /*,
        screencastFrame: function (data) {
            //console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)', data);
        }*/
  },
  methods: {
    ...mapActions('captures', ['stopTesting']),
    emitSubmit() {
      this.$emit('submit', this.runScript);
    },
    async runScript(form) {
      const testScript = this.testScript;
      const data = {
        ...form,
        captureScreencast: true,
        script: testScript,
        isTest: true
      };
      this.$refs.vxTabs.setActiveTab(1);

      const tenant = await this.$store.dispatch('accounts/tenant', null, { root: true });
      if (tenant) {
        data.tenantId = tenant.id;
      }
      //console.log('tenant is ', tenant.id);

      runTest.bind(this)(data);

      this.$store.dispatch('captures/script', data);
    }
  },
  async beforeDestroy() {
    this.$store.dispatch('captures/stopTesting');
  }
};
</script>

<template>
  <div class="vx-textscript vx-row -mx-2 flex-nowrap">
    <vs-alert :active="testRunning" icon-pack="feather" icon="icon-loader" class="con-icon--spin">
      <span>Please wait while the script is running...</span>
    </vs-alert>
    <div class="vx-col px-2 w-16">
      <vs-tooltip position="right" v-for="(size, i) in viewports || []" :key="i" :text="size">
        <vs-avatar
          size="medium"
          :color="size === currentScreenSize ? 'primary' : 'grey'"
          icon-pack="feather"
          :icon="getIconBySize(size)"
          @click="setScreenSize(size)"
        />
        <span></span>
      </vs-tooltip>
    </div>
    <div class="vx-col px-2 flex-1" v-if="screenshots">
      <ul class="vs-ul-images vs-images--ul">
        <li class="vs-image" v-for="(screenshot, i) in screenshots" :key="i">
          <div class="con-vs-image">
            <img @click="openGallery(i)" class="vs-image--img" 
            :src="screenshot.image.thumbnail" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      currentScreenSize: null
    };
  },
  computed: {
    ...mapState('captures', ['item', 'testRunning']),
    viewports() {
      const viewports = new Array();
      const item = this.item;
      function concatScreenshots(screenshots) {
        for (let i = 0; i < screenshots.length; i++) {
          const item = screenshots[i];
          const viewPort = item.viewPort;
          const exists = viewports.find(s => s.includes(`${viewPort.width}x`));
          if (!exists) {
            viewports.push(`${viewPort.width}x${viewPort.height}`);
          } else {
            const [w, h] = exists.split('x');
            if (Number(h) !== viewPort.height) {
              const indexOfMatchingWidth = viewports.indexOf(exists);
              viewports.splice(indexOfMatchingWidth, 1, `${w}x`);
            }
          }
        }
      }

      if (item && item.screenshots && item.screenshots.length > 0) concatScreenshots(item.screenshots);

      console.log('viewports are', viewports);
      return viewports;
    },
    screenshots() {
      if (!this.currentScreenSize) {
        console.log('currentScreenSize is null');
        return null;
      }
      
      const screenshots = (this.item ? this.item.screenshots : []) || [];
      if (screenshots.length === 0) return null;

      const [selectedScreenWidth] = this.currentScreenSize.split('x');
      var visibleScreenshots = screenshots.filter(item => {
        const viewport = `${item.viewPort.width}x${item.viewPort.height}`;
        return viewport.includes(`${selectedScreenWidth}x`);
      });

      return visibleScreenshots;
    },
    maxWidth() {
      try {
        return this.screencast.viewPort.width + 'px';
      } catch (error) {
        return '100%';
      }
    }
  },
  methods: {
    setScreenSize(size) {
      console.log('setScreenSize', size);
      this.currentScreenSize = size;
    },
    getIconBySize(size) {
      const [w] = size.split('x');
      return w > 768 ? 'icon-monitor' : 'icon-smartphone';
    },
    openGallery(index) {
      this.$vxPopup.open({
        attrs: {
          title: this.item.title,
          fullscreen: true,
          class: 'image-gallery'
        },
        config: {
          component: 'ImageGallery',
          index,
          srcs: (this.screenshots || []).map(item => item.image.full)
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      const viewports = this.viewports;
      if (viewports && viewports.length > 0) this.currentScreenSize = viewports[0];
    });
  },
  watch: {
    viewports(array) {
      this.$nextTick(() => {
        if (array && array.length > 0) this.currentScreenSize = array[0];
      });
    }
  }
};
</script>

<template>
  <div class="vx-cron">
    <div class="vx-row -mx-2">
      <div class="vx-col px-2 md:w-3/5">
        <div class="vx-cron__field">
          <label class="vs-input--label block">{{ mode === 'daily' ? 'Choose days' : 'Choose a week day' }}</label>
          <div class="con-text-validation span-text-validation vs-input--text-validation-span">
            <span class="span-text-validation">On which {{ mode === 'daily' ? 'days' : 'day' }} should we repeat the monitoring.</span>
          </div>
          <ul class="flex mt-1">
            <li v-for="day in builder.daysOptions" :key="day">
              <vs-avatar
                size="36px"
                :text="day"
                @click="builder.updateDayOfWeek(day)"
                :color="builder.selectedDays.includes(day) ? 'primary' : 'grey'"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-col px-2 md:w-2/5" v-show="builder.mode === 'monthly'">
        <div class="vx-cron__field">
          <label class="vs-input--label block">Occurence</label>
          <div class="con-text-validation span-text-validation vs-input--text-validation-span">
            <span class="span-text-validation">On which occurrence of the month.</span>
          </div>
          <div class="flex items-center text-sm mt-2">
            <span>Every</span>
            <div class="relative w-18 ml-2 mr-2">
              <v-select
                ref="select"
                :clearable="false"
                :options="builder.occuranceOptions"
                v-model="builder.occurance"
                name="Occurence"
                label="text"
                :searchable="false"
                :reduce="option => option.flag"
              />
            </div>
            <span>{{ builder.selectedDay }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row -mx-2 mt-base">
      <div class="vx-col px-2 md:w-1/2">
        <div class="vx-cron__field">
          <label class="vs-input--label block">Capture time</label>
          <div class="flex items-center text-sm mt-2 pl-2">
            <span>At</span>
            <div class="relative w-16 ml-2 mr-2">
              <v-select
                :clearable="false"
                :searchable="false"
                :options="builder.hoursOptions"
                v-model="builder.hours"
                :reduce="option => option.value"
              />
            </div>
            <span class="font-semibold text-lg">:</span>
            <div class="relative w-16 ml-2 mr-2">
              <v-select
                :clearable="false"
                autocomplete
                v-model="builder.minutes"
                :options="builder.minutesOptions"
                :reduce="option => option.value"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col px-2 md:w-1/2">
        <div class="vx-cron__field">
          <label class="vs-input--label block">Timezone</label>
          <v-select class="w-full mt-2 v-select--tz" :clearable="false" autocomplete :options="builder.timezoneOptions" v-model="builder.timezone" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CronBuilder from '@/components/vx-cron-builder';
export default {
  props: {
    builder: {
      type: Object,
      default: new CronBuilder()
    },
    value: Object
  },
  computed: {
    mode() {
      return this.builder.mode;
    }
  },
  data: () => ({}),
  methods: {
    emitCronValue() {
      this.$emit('input', {
        schedule: this.builder.build(),
        timeZone: this.builder.timezone
      });
    }
  },
  created() {
    if (!this.value) return this.emitCronValue();
    this.builder.schedule = this.value.schedule;
  },
  watch: {
    'builder.params': {
      deep: true,
      handler() {
        this.emitCronValue();
      }
    },
    'builder.timezone'() {
      this.emitCronValue();
    }
    // value(val) {
    //   console.log(val)
    //   this.builder.timezone = val.timeZone;
    //   this.builder.schedule = val.schedule;
    // }
  }
};
</script>

<style lang="scss">
.vx-cron {
  &__field {
    .v-select:not(.v-select--tz) {
      min-height: 34px;
      [role='listbox'] {
        width: 100%;
        min-width: unset;
      }
      .vs__dropdown-toggle {
        min-height: 28px;
      }
      .vs__dropdown-menu {
        top: calc(100% - 7px);
      }
      .vs__search {
        display: none;
      }
    }
  }
}
</style>

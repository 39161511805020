<template>
  <section class="vx-screen-recorder vx-row">
    <div
      class="vx-col w-full lg:w-2/3"
      @click="
        $vs.notify({
          title: 'Info',
          text: 'You must first select an action from the table',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        })
      "
    >
      <div class="rounded-lg vx-screen-recorder__iframe" :class="{ 'pointer-events-none': !inspectEnabled }">
        <iframe ref="iframe" :src="data.url" width="100%" height="100%" frameborder="0"></iframe>
      </div>
    </div>
    <div class="flex flex-col vx-col w-full lg:w-1/3">
      <div class="vx-screen-recorder__table">
        <table class="vs-table vs-table--tbody-table">
          <thead class="vs-table--thead">
            <tr>
              <th style="width: 140px;"><div class="vs-table-text">Action</div></th>
              <th><div class="vs-table-text">Element</div></th>
              <th></th>
            </tr>
          </thead>
          <draggable tag="tbody" v-model="form" group="options">
            <tr v-for="(action, i) in form" :key="i" class="tr-values vs-table--tr tr-table-state-null hoverFlat">
              <!---->
              <td class="td vs-table--td">
                <div class="flex">
                  <feather-icon icon="MenuIcon" svgClasses="h-5 w-5 mr-2 hover:text-primary stroke-current cursor-move" />
                  <vx-field
                    ref="v-select"
                    type="v-select"
                    class="v-select__recorder w-full text-sm"
                    :clearable="false"
                    v-model="form[i].key"
                    :options="{
                      items: availableEvents
                    }"
                  />
                </div>
              </td>
              <td class="td vs-table--td">
                <vx-field
                  v-if="action.key !== 'navigate' && action.key !== 'comment'"
                  type="input"
                  v-model="form[i].selector"
                  placeholder="'select element from the viewer'"
                  class="w-full mb-1"
                />
                <vx-field
                  v-if="action.key !== 'click'"
                  type="input"
                  v-model="form[i].value"
                  :placeholder="action.key === 'navigate' ? 'Start typing URL...' : 'Start typing...'"
                  class="w-full"
                />
              </td>
              <td class="td vs-table--td">
                <div class="flex mt-2">
                  <feather-icon
                    v-if="!continousActions"
                    icon="EditIcon"
                    svgClasses="h-5 w-5 hover:text-primary stroke-current cursor-pointer"
                    @click="onEdit(action)"
                  />
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="h-5 w-5 hover:text-danger stroke-current cursor-pointer ml-2"
                    @click="onDelete(action)"
                  />
                </div>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
      <vs-divider class="w-full"></vs-divider>
      <div class="flex w-full">
        <vs-checkbox v-model="continousActions">Continous actions</vs-checkbox>
        <vs-button v-if="!continousActions" @click="onCreate" class="ml-auto">Add Action</vs-button>
      </div>
      <div class="w-full" v-if="continousActions">
        <div class="pl-8 mt-2">
          <p class="pl-1 text-sm"
            >This feature allows you to continuously perform actions and automatically record each one of them. Start by selecting an element from the
            viewer.</p
          >
        </div>
      </div>
      <div class="w-full flex mt-auto">
        <vs-button @click="generateCode()" class="ml-auto" color="success" :disabled="form.length === 0">Generate Code</vs-button>
        <vs-button class="ml-2" type="border" @click="$emit('close')">Cancel</vs-button>
      </div>
    </div>
  </section>
</template>

<script>
import draggable from 'vuedraggable';
import iframeURLChange from './iframechange';
export default {
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      continousActions: true,
      intervalId: undefined,
      availableEvents: ['click', 'type', 'navigate', 'comment'],
      form: [],
      activeAction: undefined
    };
  },
  computed: {
    inspectEnabled() {
      if (this.continousActions) return true;
      if (this.activeAction) return true;
      return false;
    }
  },
  methods: {
    async getService() {
      const me = this;
      return new Promise(resolve => {
        me.intervalId = setInterval(() => {
          console.log('Running...', new Date());
          const iframe = (me.$refs || {}).iframe;
          console.log(iframe.contentWindow);
          if (iframe && iframe.contentWindow) {
            if (iframe.contentWindow.__PAGEWITNESS__INSPECTOR__) resolve(iframe.contentWindow.__PAGEWITNESS__INSPECTOR__);
          }
        }, 350);
      });
    },
    onUpdate(e) {
      const { activeAction } = this;

      if (!activeAction) {
        this.create(e);
        return;
      }

      if (this.continousActions) {
        if (activeAction.selector !== e.selector) {
          this.create(e);
          return;
        }
      }

      // Single action at a time
      const activeIndex = this.form.findIndex(item => item._id === activeAction._id);
      this.form.splice(activeIndex, 1, {
        ...this.form[activeIndex],
        selector: e.selector,
        key: e.key,
        ...(e.value
          ? {
              value: e.value
            }
          : {})
      });

      if (this.continousActions) return;
    },
    onCreate() {
      this.create({
        key: 'click'
      });
    },
    create(action) {
      console.log('Target of action is', action.target);
      this.activeAction = {
        _id: this.$randomId(),
        key: action.key,
        value: action.value,
        selector: action.selector,
        href: action.target ? action.target.href : null
      };
      this.form.push(this.activeAction);

      this.$nextTick(() => {
        if (this.$refs['v-select']) {
          const vSelect = this.$refs['v-select'][0];
          const keys = Object.keys(vSelect.$refs);
          window.sel = vSelect.$refs[keys[0]];
        }
      });
    },
    onEdit(tr) {
      this.activeAction = tr;
    },
    onDelete(tr) {
      const activeIndex = this.form.findIndex(item => item._id === tr._id);
      this.form.splice(activeIndex, 1);
    },

    fixUrlHost(theUrl) {
      var baseUrl = new URL(this.data.url);
      var url = new URL(theUrl, baseUrl);
      url.host = url.host.replaceAll('-pwproxy.pagewitness.com','').replaceAll('-','.');
      var href = url.toString();
      return href;
    },

    generateCode() {
      console.log('The FORM', this.form);
      var script = '';
      var idx=0;

      

      for (var step of this.form) {
        idx++;
        //console.log(step);
  /* key: "click"
selector: ".section-welcome-text:nth-child(1) > p"
value: undefined
_id: "_7njizxsq2" */
        if (step.key == 'comment') {
          script += '// ' + step.value + '\r\n';
        }
        else {
          var element = `element${idx}`;
          var selector = `var ${element} = await page.$('${step.selector}');`
          var action = `await ${element}.${step.key}();`;
          script += `${selector}\r\n${action}\r\n`;

          if (step.href) {
            //flipmind-com
            
            var href = this.fixUrlHost(step.href);
            script += `// Waiting for ${href} to load\r\n`;
            script += 'await page.waitForNavigation();\r\n';
          }
        }
      }



      this.$emit('submit', script);
    },
  },
  async mounted() {
    var me = this;
    this.$nextTick(() => {
      this.getService().then(inspector => {
        clearInterval(this.intervalId);
        inspector.$on('input', e => this.onUpdate(e));
      });

      if (this.$refs['v-select']) {
        const vSelect = this.$refs['v-select'][0];
        const keys = Object.keys(vSelect.$refs);
        window.sel = vSelect.$refs[keys[0]];
      }

      // Usage:
      const iframe = (me.$refs || {}).iframe;
      console.log('iframeURLChange registered', iframe);
      var isFirstLoad=true;

      iframeURLChange(iframe, function (newURL) {
          console.log("iFrame URL changed:", newURL);
          if (!isFirstLoad) {
            me.create(
            {
                key: 'comment',
                value: 'Navigated to ' + me.fixUrlHost(newURL),
                selector: null,              
            });
          }
          else {
            isFirstLoad=false;
          }
      });
    });


  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  watch: {
    continousActions(value) {
      if (!value) {
        this.activeAction = undefined;
      }
    }
  },
  components: {
    draggable
  }
};
</script>
